import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import { type FragmentPointOfInterestForSiteMaterialFlow } from '../../../../gql-types/generated-types-super-graph';
import { Icon, MaterialName, MaterialNameCell } from '../styles';
import { PoiRow } from './row/Row';

type Props = {
  onClick: () => void;
  collapsedIds: string[];
  pois: FragmentPointOfInterestForSiteMaterialFlow[];
};

export const Pois: FC<Props> = ({ collapsedIds, onClick, pois }) => {
  const { t } = useTranslation();

  return (
    <>
      <tr>
        <MaterialNameCell colSpan={5} onClick={onClick}>
          <MaterialName>
            <Icon icon="generic-fuel-pump" />
            <Typography
              variant="subtitle1"
              style={{ display: 'inline', paddingRight: '5px', color: '#575757' }}
            >
              {t('production-data.fuel-stations')}
            </Typography>
            <Icon icon={collapsedIds.includes('FUEL_STATIONS') ? 'chevron-down' : 'chevron-up'} />
          </MaterialName>
        </MaterialNameCell>
      </tr>
      {!collapsedIds.includes('FUEL_STATIONS') &&
        pois
          .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
          .map((poi) => <PoiRow key={poi.id} poi={poi} />)}
    </>
  );
};
