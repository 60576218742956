import { isNumber } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, TagType, Typography } from '@volvo/vce-uikit';
import type {
  FragmentPointOfInterestForSiteMaterialFlow,
  FragmentSiteMaterialFlowWithMetricData,
} from '../../../../gql-types/generated-types-super-graph';
import { Container } from './styles';

type Props = {
  materialFlowData: FragmentSiteMaterialFlowWithMetricData[];
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  lastFetch?: number;
};

export const Header: FC<Props> = ({ materialFlowData, pointOfInterestData, lastFetch }) => {
  const { t } = useTranslation();

  const source = useMemo(() => {
    const timestamp = DateTime.fromMillis(
      Math.max(
        ...[...materialFlowData, ...pointOfInterestData]
          .map((i) => i.siteMetricDefinitions)
          .flat()
          .map((j) =>
            j.currentSiteMetricData?.originCreatedAt
              ? DateTime.fromISO(j.currentSiteMetricData.originCreatedAt).toMillis()
              : null,
          )
          .filter((i) => isNumber(i)),
      ),
    );

    if (!timestamp.isValid) {
      return { timestamp: t('N/A'), type: TagType.WarningSubtle };
    }

    const isToday = timestamp.hasSame(DateTime.local(), 'day');
    const formatted = timestamp.toFormat(isToday ? 'HH:mm' : 'dd/MM HH:mm');

    return {
      timestamp: `${isToday ? t('Today') : ''} ${formatted}`,
      type:
        DateTime.local().diff(timestamp).as('minutes') < 10
          ? TagType.SuccessSubtle
          : TagType.WarningSubtle,
    };
  }, [materialFlowData, pointOfInterestData, t]);

  const fetch = useMemo(() => {
    if (!lastFetch) {
      return { timestamp: t('N/A'), type: TagType.WarningSubtle };
    }

    const isToday = DateTime.fromMillis(lastFetch).hasSame(DateTime.local(), 'day');
    const formatted = DateTime.fromMillis(lastFetch).toFormat(isToday ? 'HH:mm' : 'dd/MM HH:mm');

    return {
      timestamp: `${isToday ? t('Today') : ''} ${formatted}`,
      type:
        lastFetch && DateTime.local().diff(DateTime.fromMillis(lastFetch)).as('minutes') < 10
          ? TagType.SuccessSubtle
          : TagType.WarningSubtle,
    };
  }, [lastFetch, t]);

  return (
    <Container>
      <Typography variant="heading2" style={{ marginRight: '16px' }}>
        {t('production-data.process')}
      </Typography>
      <Tag type={fetch.type} icon="checkmark-circle">
        {t('production-data.page-updated') + fetch.timestamp}
      </Tag>
      <Tag type={source.type} icon="checkmark-circle">
        {t('production-data.source-updated') + source.timestamp}
      </Tag>
    </Container>
  );
};
