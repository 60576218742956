import { DateTime } from 'luxon';
import type { FragmentSiteMetricDefinitionData } from '../../../../gql-types/generated-types-super-graph';

export const getTooltipContent = (metricDefinition?: FragmentSiteMetricDefinitionData) => {
  if (metricDefinition?.name || metricDefinition?.currentSiteMetricData?.originCreatedAt) {
    const originCreatedAt = metricDefinition?.currentSiteMetricData?.originCreatedAt;

    if (originCreatedAt) {
      return (
        <>
          {metricDefinition?.name}
          <br />
          {DateTime.fromISO(originCreatedAt).toLocaleString(DateTime.DATETIME_SHORT)}
        </>
      );
    }

    return <>{metricDefinition?.name}</>;
  }
  return null;
};
