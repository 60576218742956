import { type FC } from 'react';
import { useSiteConfigContext } from '../../../../../../context/site-config/SiteConfigContext';
import {
  MetricDefinitionType,
  type FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../../../../gql-types/generated-types-super-graph';
import { TabKeys, type Tab } from '../../../types';
import { DataCell } from '../../data-cell/DataCell';
import { getMetric, getPoiName } from '../../helpers';
import { StatusIcon } from '../../status-icon/StatusIcon';
import { Row } from '../../styles';

type Props = {
  siteMaterialFlow: FragmentSiteMaterialFlowWithMetricData;
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  tab: Tab;
};

export const SensorRow: FC<Props> = ({ siteMaterialFlow, pointOfInterestData, tab }) => {
  const { units } = useSiteConfigContext();

  const statusIndicator = siteMaterialFlow.siteMetricDefinitions.find(
    ({ type }) => type === MetricDefinitionType.StatusIndicator,
  );

  const massFlowRate = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [MetricDefinitionType.MassFlowRate, MetricDefinitionType.FlowRateMass],
    units,
  );

  const level = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [
      MetricDefinitionType.LevelVolume,
      MetricDefinitionType.LevelPercent,
      MetricDefinitionType.LevelMass,
    ],
    units,
  );

  const producedMass = getMetric(
    siteMaterialFlow.siteMetricDefinitions,
    [MetricDefinitionType.ProducedMass],
    units,
  );

  const material = siteMaterialFlow.siteMetricDefinitions.find(
    ({ type }) => type === MetricDefinitionType.Material,
  );

  return (
    <Row>
      <DataCell>{siteMaterialFlow.name} </DataCell>
      {tab !== TabKeys.CRUSHERS && (
        <DataCell>{getPoiName(siteMaterialFlow, pointOfInterestData)}</DataCell>
      )}
      <DataCell metricDefinition={statusIndicator} tooltip>
        {statusIndicator ? (
          <StatusIcon status={statusIndicator?.currentSiteMetricData?.statusIndicator} />
        ) : null}
      </DataCell>
      {tab !== TabKeys.FUEL_STATIONS && (
        <DataCell metricDefinition={massFlowRate.metricDefinition} tooltip>
          {massFlowRate.value}
        </DataCell>
      )}
      {tab !== TabKeys.CRUSHERS && (
        <DataCell metricDefinition={level.metricDefinition} tooltip>
          {level.value}
        </DataCell>
      )}
      {tab === TabKeys.CRUSHERS ? (
        <>
          <DataCell>
            {!material?.materialVariant
              ? ''
              : `${material.materialVariant.name}, ${material.materialVariant.materialType.name}, ${material.materialVariant.materialType.materialFamily.name}`}
          </DataCell>
          <DataCell metricDefinition={producedMass.metricDefinition} tooltip>
            {producedMass.value}
          </DataCell>
        </>
      ) : null}
    </Row>
  );
};
