import styled from '@emotion/styled';

export const HeaderRow = styled.tr`
  background-color: #fff;
  border-bottom: 4px solid #e1e1e1;
  height: 52px;
`;

export const HeaderCell = styled.th`
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  line-height: 52px;
  vertical-align: middle;
  padding: 0 30px 0 10px;
`;
