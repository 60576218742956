import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import { HeaderCell, HeaderRow } from './styles';

export const TableHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <HeaderRow>
        <HeaderCell>
          <Typography variant="subtitle2">{t('production-data.columns.name')}</Typography>
        </HeaderCell>
        <HeaderCell>
          <Typography variant="subtitle2">{t('production-data.columns.location')}</Typography>
        </HeaderCell>
        <HeaderCell>
          <Typography variant="subtitle2">{t('production-data.columns.status')}</Typography>
        </HeaderCell>
        <HeaderCell>
          <Typography variant="subtitle2">{t('production-data.columns.flow-rate')}</Typography>
        </HeaderCell>
        <HeaderCell>
          <Typography variant="subtitle2">{t('production-data.columns.level')}</Typography>
        </HeaderCell>
      </HeaderRow>
    </thead>
  );
};
