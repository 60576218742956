import styled from '@emotion/styled';
import { SystemIcon } from '@volvo/vce-uikit';

export const TableBody = styled.tbody`
  & tr:nth-of-type(odd) {
    background-color: #f7f7f7;
  }
`;

export const MaterialName = styled.span`
  padding-right: 10px;
`;

export const MaterialNameCell = styled.td`
  font-weight: bold;
  font-size: 14px;
  line-height: 37px;
  vertical-align: middle;
  background-color: #ededed;
  padding: 0 10px;
  cursor: pointer;
`;

export const Icon = styled(SystemIcon)`
  display: inline;
  padding-right: 5px;
  vertical-align: middle;
  padding-bottom: 3px;
`;

export const StyledTable = styled.table`
  width: 100%;
  margin: 32px;
`;

export const Row = styled.tr`
  background-color: #fff;
`;
