import { filter, groupBy, map, pipe } from 'ramda';
import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PointOfInterestType } from '@volvo/vce-package-site-mapcommon';
import {
  FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../../gql-types/generated-types-super-graph';
import { isNotNull } from '../../../../helpers';
import { TabKeys, type Tab } from '../types';
import { FuelStations } from './fuel-stations/FuelStations';
import { compareMaterialFamily } from './helpers';
import { MaterialRow } from './material-row/MaterialRow';
import { StyledTable, TableBody } from './styles';
import { TableHeader } from './table-header/TableHeader';

type Props = {
  materialFlowData: FragmentSiteMaterialFlowWithMetricData[];
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  tab: Tab;
};

type FlowWithPoi = FragmentSiteMaterialFlowWithMetricData & {
  poi?: FragmentPointOfInterestForSiteMaterialFlow;
};

export const Table: FC<Props> = ({ materialFlowData, pointOfInterestData, tab }) => {
  const { t } = useTranslation();
  const [collapsedIds, setCollapsedIds] = useState<string[]>([]);

  const handleCollapse = (id: string) => {
    if (collapsedIds.includes(id)) {
      setCollapsedIds(collapsedIds.filter((i) => i !== id));
    } else {
      setCollapsedIds([...collapsedIds, id]);
    }
  };

  const factoryAndSiloPoiIds = pointOfInterestData
    .filter(
      ({ pointOfInterestType }) =>
        pointOfInterestType.type === PointOfInterestType.FACTORY ||
        pointOfInterestType.type === PointOfInterestType.SILO,
    )
    .map(({ id }) => id);

  const factoryAndSilos: Partial<Record<string, FragmentSiteMaterialFlowWithMetricData[]>> = pipe(
    filter<FragmentSiteMaterialFlowWithMetricData>((i) =>
      factoryAndSiloPoiIds.includes(i.fromId || i.toId),
    ),
    groupBy<FragmentSiteMaterialFlowWithMetricData>(
      ({ materialVariant }) =>
        materialVariant?.materialType.materialFamily.id ?? 'MATERIAL_MISSING',
    ),
  )(materialFlowData);

  const cruherPoiIds = pointOfInterestData
    .filter(({ pointOfInterestType }) => pointOfInterestType.type === PointOfInterestType.CRUSHER)
    .map(({ id }) => id);

  const crushers: Partial<Record<string, FlowWithPoi[]>> = pipe(
    map<FragmentSiteMaterialFlowWithMetricData, FlowWithPoi>((i) => {
      const poiId = cruherPoiIds.find((id) => id === (i.fromId || i.toId));
      return { ...i, poi: pointOfInterestData.find(({ id }) => id === poiId) };
    }),
    filter<FlowWithPoi>((i) => i?.poi !== undefined),
    groupBy<FlowWithPoi>((i) => i.poi?.id ?? 'CRUSHER_MISSING'),
  )(materialFlowData);

  const fuelStations = pointOfInterestData
    .filter(({ pointOfInterestType }) => pointOfInterestType.type === 'FUEL_STATION')
    .filter(isNotNull);

  return (
    <StyledTable>
      <TableHeader tab={tab} />
      <TableBody>
        {tab === TabKeys.FACTORIES_AND_SILOS ? (
          Object.entries(factoryAndSilos)
            .sort(([, a], [, b]) => compareMaterialFamily(a, b))
            .map(([id, siteMaterialFlows]) =>
              !siteMaterialFlows ? null : (
                <MaterialRow
                  key={id}
                  id={id}
                  siteMaterialFlows={siteMaterialFlows}
                  onClick={() => handleCollapse(id)}
                  collapsedIds={collapsedIds}
                  pointOfInterestData={pointOfInterestData}
                  tab={tab}
                  title={
                    id === 'MATERIAL_MISSING'
                      ? t('unknown-material')
                      : siteMaterialFlows[0].materialVariant?.materialType.materialFamily.name
                  }
                />
              ),
            )
        ) : tab === TabKeys.CRUSHERS ? (
          Object.entries(crushers).map(([id, siteMaterialFlows]) =>
            !siteMaterialFlows ? null : (
              <MaterialRow
                key={id}
                id={id}
                siteMaterialFlows={siteMaterialFlows}
                onClick={() => handleCollapse(id)}
                collapsedIds={collapsedIds}
                pointOfInterestData={pointOfInterestData}
                tab={tab}
                title={
                  id === 'CRUSHER_MISSING' ? t('unknown-crusher') : siteMaterialFlows[0].poi?.name
                }
              />
            ),
          )
        ) : tab === TabKeys.FUEL_STATIONS ? (
          <FuelStations rows={fuelStations} />
        ) : null}
      </TableBody>
    </StyledTable>
  );
};
