import { groupBy, isEmpty, isNotEmpty } from 'ramda';
import { useState, type FC } from 'react';
import { EmptyState } from '../../../components/common/empty-state/EmptyState';
import { CenteredSpinner } from '../../../components/common/loading/CenteredSpinner';
import {
  FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../gql-types/generated-types-super-graph';
import { isNotNull } from '../../../helpers';
import { Header } from './header/Header';
import { compareMaterialFamily } from './helpers';
import { MaterialRow } from './material-row/MaterialRow';
import { Pois } from './pois/Pois';
import { Container, StyledTable, TableBody } from './styles';
import { TableHeader } from './table-header/TableHeader';

type Props = {
  materialFlowData: FragmentSiteMaterialFlowWithMetricData[];
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  loading: boolean;
  lastFetch?: number;
};

export const OldTable: FC<Props> = ({
  materialFlowData,
  pointOfInterestData,
  loading,
  lastFetch,
}) => {
  const [collapsedIds, setCollapsedIds] = useState<string[]>([]);

  const handleCollapse = (id: string) => {
    if (collapsedIds.includes(id)) {
      setCollapsedIds(collapsedIds.filter((i) => i !== id));
    } else {
      setCollapsedIds([...collapsedIds, id]);
    }
  };

  const grouped = groupBy<FragmentSiteMaterialFlowWithMetricData>(
    ({ materialVariant }) => materialVariant?.materialType.materialFamily.id ?? 'MATERIAL_MISSING',
    materialFlowData,
  );

  const pois = pointOfInterestData
    .filter(({ pointOfInterestType }) => pointOfInterestType.type === 'FUEL_STATION')
    .filter(isNotNull);

  return (
    <>
      <Header
        materialFlowData={materialFlowData}
        pointOfInterestData={pointOfInterestData}
        lastFetch={lastFetch}
      />
      <Container>
        <StyledTable>
          <TableHeader />
          <TableBody>
            {isNotEmpty(grouped)
              ? Object.entries(grouped)
                  .sort(([, a], [, b]) => compareMaterialFamily(a, b))
                  .map(([id, siteMaterialFlows]) =>
                    !siteMaterialFlows ? null : (
                      <MaterialRow
                        key={id}
                        id={id}
                        siteMaterialFlows={siteMaterialFlows}
                        onClick={() => handleCollapse(id)}
                        collapsedIds={collapsedIds}
                        pointOfInterestData={pointOfInterestData}
                      />
                    ),
                  )
              : null}
            {pois.length ? (
              <Pois
                pois={pois}
                onClick={() => handleCollapse('FUEL_STATIONS')}
                collapsedIds={collapsedIds}
              />
            ) : null}
          </TableBody>
        </StyledTable>
        {loading && <CenteredSpinner style={{ position: 'absolute', top: '110px' }} />}
        {!loading && isEmpty(grouped) && !pois.length && (
          <EmptyState style={{ position: 'absolute', top: '90px' }} />
        )}
      </Container>
    </>
  );
};
