import styled from '@emotion/styled';

export const Container = styled.section`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.bg};
`;
