import { useRef, useState, type FC, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@volvo/vce-uikit';
import type { FragmentSiteMetricDefinitionData } from '../../../../gql-types/generated-types-super-graph';
import { useHoverDebounced } from '../../../../helpers/hooks/useHoverDebounced';
import { getTooltipContent } from './getTooltipContent';
import { TD } from './styles';

type Props = {
  metricDefinition?: FragmentSiteMetricDefinitionData;
  tooltip?: boolean;
  tooltipPlacement?: 'right' | 'left';
};

export const DataCell: FC<PropsWithChildren<Props>> = ({
  metricDefinition,
  tooltip,
  tooltipPlacement,
  children,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipContent = getTooltipContent(metricDefinition);

  const isHovering = tooltip
    ? useHoverDebounced<HTMLSpanElement | undefined>(ref.current || undefined, 500)
    : false;

  return (
    <TD>
      <span ref={ref}>{children}</span>
      <Tooltip
        reference={ref.current}
        open={isHovering && tooltipOpen}
        setOpen={setTooltipOpen}
        offset={16}
        placement={tooltipPlacement}
      >
        {tooltipContent || t('production-data.no-data')}
      </Tooltip>
    </TD>
  );
};
