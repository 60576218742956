import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusLight, StatusLightStatus } from '@volvo/vce-uikit';
import {
  PointOfInterestStatus,
  SiteMetricDataStatusIndicator,
} from '../../../../gql-types/generated-types-super-graph';

type Status = PointOfInterestStatus | SiteMetricDataStatusIndicator | null;
export const StatusIcon: FC<{
  status?: Status;
  type?: 'poi' | 'metric';
}> = ({ status, type = 'metric' }) => {
  const { t } = useTranslation();

  let statusIcon: StatusLightStatus | null;
  let statusText: string;
  if (type === 'poi') {
    if (status === PointOfInterestStatus.Closed) {
      statusIcon = StatusLightStatus.Error;
      statusText = t('production-data.status.closed');
    } else {
      statusIcon = null;
      statusText = '';
    }
  } else {
    if (status === SiteMetricDataStatusIndicator.Active) {
      statusIcon = StatusLightStatus.Success;
      statusText = t('production-data.status.on');
    } else if (status === SiteMetricDataStatusIndicator.Inactive) {
      statusIcon = StatusLightStatus.Neutral;
      statusText = t('production-data.status.off');
    } else {
      statusIcon = null;
      statusText = '-';
    }
  }

  return (
    <>{statusIcon ? <StatusLight status={statusIcon}>{statusText}</StatusLight> : statusText}</>
  );
};
