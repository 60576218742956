import { type FC } from 'react';
import { type FragmentPointOfInterestForSiteMaterialFlow } from '../../../../../gql-types/generated-types-super-graph';
import { FuelStationsRow } from './row/Row';

type Props = {
  rows: FragmentPointOfInterestForSiteMaterialFlow[];
};

export const FuelStations: FC<Props> = ({ rows }) => {
  return (
    <>
      {rows
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        .map((poi) => (
          <FuelStationsRow key={poi.id} poi={poi} />
        ))}
    </>
  );
};
