import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@emotion/react';
import { useMemo, useState, type FC, type PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { FeatureFlagsProvider, useFeatureFlag } from '@volvo/vce-package-featureflags-client';
import { useAuth } from '@volvo/vce-uikit';
import { SiteConfigProvider } from '../../context/site-config/SiteConfigContext';
import { useQuerySiteMaterialFlowsWithMetricData } from '../../gql-types/generated-types-super-graph';
import { getApolloClient } from '../../gql/apollo-client';
import { isNotNull } from '../../helpers';
import mapI18n from '../../i18n';
import { theme } from '../../theme';
import { Content } from './content/Content';
import { OldTable } from './old-table/OldTable';

const ProductionDataContainer: FC<{ siteId: string }> = ({ siteId }) => {
  const [lastFetch, setLastFetch] = useState<number>();
  const response = useQuerySiteMaterialFlowsWithMetricData({
    variables: { siteId },
    pollInterval: Number(import.meta.env.VITE_POLL_INTERVAL),
    // onCompleted won't be called unless this is set...
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLastFetch(Date.now()),
  });

  const { value: newProcessPage } = useFeatureFlag('new-process-page', false);

  if (!newProcessPage) {
    return (
      <OldTable
        loading={response.loading}
        materialFlowData={
          response.data?.siteMaps.site?.siteMaterialFlows.edges
            .map(({ node }) => node)
            .filter(isNotNull) || []
        }
        pointOfInterestData={response.data?.siteMaps.site?.pointsOfInterest || []}
        lastFetch={lastFetch}
      />
    );
  }

  return (
    <Content
      loading={response.loading}
      materialFlowData={
        response.data?.siteMaps.site?.siteMaterialFlows.edges
          .map(({ node }) => node)
          .filter(isNotNull) || []
      }
      pointOfInterestData={response.data?.siteMaps.site?.pointsOfInterest || []}
      lastFetch={lastFetch}
    />
  );
};

export type Props = { siteId: string };
export const ProductionData: FC<PropsWithChildren<Props>> = ({ siteId }) => {
  const { getToken } = useAuth();
  const client = useMemo(() => getApolloClient(getToken), [getToken]);

  return (
    <I18nextProvider i18n={mapI18n}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <FeatureFlagsProvider
            environment={import.meta.env.VITE_FF_ENV}
            appKey="site-map"
            authTokenResolver={getToken}
          >
            <SiteConfigProvider siteId={siteId}>
              <ProductionDataContainer siteId={siteId} />
            </SiteConfigProvider>
          </FeatureFlagsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </I18nextProvider>
  );
};
