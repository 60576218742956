import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import {
  type FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../../gql-types/generated-types-super-graph';
import { Icon, MaterialName, MaterialNameCell } from '../styles';
import { SensorRow } from './sensor-row/SensorRow';

type Props = {
  onClick: () => void;
  id: string;
  siteMaterialFlows: FragmentSiteMaterialFlowWithMetricData[];
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  collapsedIds: string[];
};

export const MaterialRow: FC<Props> = ({
  id,
  collapsedIds,
  onClick,
  siteMaterialFlows,
  pointOfInterestData,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <tr>
        <MaterialNameCell colSpan={5} onClick={onClick}>
          <MaterialName>
            <Typography
              variant="subtitle1"
              style={{ display: 'inline', paddingRight: '5px', color: '#575757' }}
            >
              {id === 'MATERIAL_MISSING'
                ? t('unknown-material')
                : siteMaterialFlows[0].materialVariant?.materialType.materialFamily.name}
            </Typography>
            <Icon icon={collapsedIds.includes(id) ? 'chevron-down' : 'chevron-up'} />
          </MaterialName>
        </MaterialNameCell>
      </tr>
      {!collapsedIds.includes(id) &&
        siteMaterialFlows
          .sort((a, b) => (a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1))
          .map((siteMaterialFlow) => (
            <SensorRow
              key={siteMaterialFlow.id}
              siteMaterialFlow={siteMaterialFlow}
              pointOfInterestData={pointOfInterestData}
            />
          ))}
    </>
  );
};
