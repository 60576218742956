import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@volvo/vce-uikit';
import { TabKeys, type Tab } from '../../types';
import { HeaderCell, HeaderRow } from './styles';

type Props = {
  tab: Tab;
};

export const TableHeader: FC<Props> = ({ tab }) => {
  const { t } = useTranslation();

  return (
    <thead>
      <HeaderRow>
        <HeaderCell>
          <Typography variant="subtitle2">{t('production-data.columns.name')}</Typography>
        </HeaderCell>
        {tab !== TabKeys.CRUSHERS && (
          <HeaderCell>
            <Typography variant="subtitle2">{t('production-data.columns.location')}</Typography>
          </HeaderCell>
        )}
        <HeaderCell>
          <Typography variant="subtitle2">{t('production-data.columns.status')}</Typography>
        </HeaderCell>
        {tab !== TabKeys.FUEL_STATIONS && (
          <HeaderCell>
            <Typography variant="subtitle2">{t('production-data.columns.flow-rate')}</Typography>
          </HeaderCell>
        )}
        {tab !== TabKeys.CRUSHERS && (
          <HeaderCell>
            <Typography variant="subtitle2">{t('production-data.columns.level')}</Typography>
          </HeaderCell>
        )}
        {tab === TabKeys.CRUSHERS && (
          <>
            <HeaderCell>
              <Typography variant="subtitle2">{t('production-data.columns.material')}</Typography>
            </HeaderCell>
            <HeaderCell>
              <Typography variant="subtitle2">
                {t('production-data.columns.produced-mass')}
              </Typography>
            </HeaderCell>
          </>
        )}
      </HeaderRow>
    </thead>
  );
};
