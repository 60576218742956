import { type FC } from 'react';
import { Typography } from '@volvo/vce-uikit';
import {
  type FragmentPointOfInterestForSiteMaterialFlow,
  type FragmentSiteMaterialFlowWithMetricData,
} from '../../../../../gql-types/generated-types-super-graph';
import { type Tab } from '../../types';
import { Icon, MaterialName, MaterialNameCell } from '../styles';
import { SensorRow } from './sensor-row/SensorRow';

type Props = {
  onClick: () => void;
  id: string;
  siteMaterialFlows: FragmentSiteMaterialFlowWithMetricData[];
  pointOfInterestData: FragmentPointOfInterestForSiteMaterialFlow[];
  collapsedIds: string[];
  tab: Tab;
  title?: string;
};

export const MaterialRow: FC<Props> = ({
  id,
  collapsedIds,
  onClick,
  siteMaterialFlows,
  pointOfInterestData,
  tab,
  title,
}) => (
  <>
    <tr>
      <MaterialNameCell colSpan={5} onClick={onClick}>
        <MaterialName>
          <Typography
            variant="subtitle1"
            style={{ display: 'inline', paddingRight: '5px', color: '#575757' }}
          >
            {title}
          </Typography>
          <Icon icon={collapsedIds.includes(id) ? 'chevron-down' : 'chevron-up'} />
        </MaterialName>
      </MaterialNameCell>
    </tr>
    {!collapsedIds.includes(id) &&
      siteMaterialFlows
        .sort((a, b) => (a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1))
        .map((siteMaterialFlow) => (
          <SensorRow
            key={siteMaterialFlow.id}
            siteMaterialFlow={siteMaterialFlow}
            pointOfInterestData={pointOfInterestData}
            tab={tab}
          />
        ))}
  </>
);
